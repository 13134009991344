

























































































































import Vue from "vue";
import * as _ from "lodash";
import fb from "firebase/app";
import db from "@/firebase/db";
import DBHelper from "@/tscript/dbHelper";
import Header from "@/components/global/Header.vue";
import { mapState, mapActions } from "vuex";
import MycButton from "@/components/global/buttons/MycButton.vue";
import MycDialog from "@/components/global/dialog/MycDialog.vue";

export default Vue.extend({
  name: "Discussion",
  components: {
    Header,
    MycButton,
    MycDialog,
  },
  data() {
    return {
      dbHelper: new DBHelper(db),
      discussion: {} as any,
      newMessage: "",
      patientsList: new Array(),
      dialogShareMedicalCase: false,
      step: 1,
      patientToShare: {} as any,
      questionToShare: "",
      receiverUser: {} as any,
    };
  },
  methods: {
    ...mapActions("analytics", ["logAction"]),
    goToEnd() {
      let elem: any = this.$refs?.["zone"];
      elem.scrollTop = elem.scrollHeight;
    },
    loadData() {
      this.dbHelper.getDocFromCollectionOnSnapshot(
        "discussions",
        this.$route.params.id,
        (item: any) => {
          this.discussion = item;
          this.receiverUser = this.findUser(this.discussion.participants);
          this.receiverUser.name =
            this.receiverUser.last_name + " " + this.receiverUser.first_name;
        }
      );
    },
    closeShareDialog() {
      this.dialogShareMedicalCase = false;
      this.step = 1;
    },
    async addPatientToAccessList() {
      const userToSend: any = await this.dbHelper.getDocFromCollection(
        "users",
        this.receiverUser.id
      );
      if (!userToSend.access_list) {
        userToSend.access_list = [];
      }
      userToSend.access_list.push(this.patientToShare.id);
      await this.dbHelper.setDataToCollection(
        "users",
        userToSend.id,
        userToSend
      );
      this.step = 1;
      this.patientToShare = {};
    },
    async send() {
      this.addPatientToAccessList();
      await this.sendMessage(this.newMessage);
      this.goToEnd();
      this.newMessage = "";
    },
    async sendMessage(message: string) {
      const FieldValue = fb.firestore.FieldValue;
      await this.dbHelper.updateDataToCollection(
        "discussions",
        this.discussion.id,
        {
          messages: FieldValue.arrayUnion({
            user_id: this.userData.id,
            content: message,
          }),
        }
      );
    },
    findUser(participants: any) {
      let user = {};
      if (participants?.length == 2) {
        const recId =
          participants[0] == this.userData.id
            ? participants[1]
            : participants[0];
        user = _.find(this.$store.state.usersList, { id: recId });
      }
      return user;
    },
    confirmSharePatient() {
      const automaticMessage =
        this.$i18n.t("global.patient_has_been_shared") +
        " " +
        this.userData.last_name +
        " " +
        this.userData.first_name +
        ". " +
        this.$i18n.t("global.find_it_in") +
        " " +
        this.patientToShare.name +
        " " +
        this.$i18n.t("global.in_your");
      this.sendMessage(automaticMessage);
      this.sendMessage(this.questionToShare);
      this.closeShareDialog();
    },
  },
  async created() {
    this.logAction({ event_name: "discussion" });
    this.patientsList = this.$store.state.patientsList ?? new Array();
    this.patientsList = this.patientsList.filter(
      (patient: any) =>
        patient.done_by == this.userData.id ||
        this.userData?.access_list?.includes(patient.done_by)
    );
    this.patientsList = this.patientsList.map((patient: any) => {
      if (!patient.name) {
        patient.name = patient.last_name + " " + patient.first_name;
      }
      return patient;
    });
    this.loadData();
  },
  computed: {
    ...mapState("user", ["userData"]),
  },
});
